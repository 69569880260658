import React, {Component} from 'react'
import {$$, _$$} from '../../helpers/localization'
import UserImage from '../shared/UserImage'
import {connect} from 'react-redux'
import {formatUtils} from '../../utils/formatUtils'
import {fetchFirstHour, fetchProvider, fetchTimetable} from '../../actions/provider_actions'
import moment from 'moment';
import history from '../../helpers/history'
import {Routes} from '../../constants/routes';
import ClinicianName from './ClinicianName'
import ReactHorizontalDatePicker from '../../components/shared/ReactHorizontalDatePicker'
import FreeSlotsList from './FreeSlotsList'
import Select from '../shared/Select'
import {ReactComponent as CalendarIcon} from '../../../public/media/icons/calendar-1.svg'
import {isSameDay} from 'date-fns'
import PropTypes from "prop-types";
import _ from "underscore";
import {appointmentUtils} from "../../utils/appointmentUtils";
import ReactMarkdown from "react-markdown";
import {CONVERTER, formatDate} from "../../utils/converter";
import ResponseTimeButton from "./ResponseTimeButton";
import {isLoggedIn} from "../../helpers/auth_helper";
import {changeLanguage} from "../../actions/language_actions";
import {providerService} from "../../service/provider_service";
import CenteredModal from "../shared/CenteredModal";
import AdditionalInfo from "./AdditionalInfo";

class ClinicianPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonsDisabled: true,
            firstHourAvailable: null,
            chosenDate: new Date(),
            triggeredFromBtn: false,
            timetableData: null,
            todaySlots: null,
            showDropDown: false,
            selectedDropDownValue: null,
            selectedAppointment: null,
            selectedSlot: null,
        }
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.i18n.selected.lang !== prevProps.i18n.selected.lang) {
            let lang = _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? 'en' : this.props.i18n.selected.lang;
            moment.locale(lang);
        }
    }

    componentDidMount() {
        let clinicianId = "";
        let priceId = "";
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('clinician')) {
            clinicianId = queryParams.get('clinician');
            if (queryParams.has('lang')) {
                let langCode = queryParams.get('lang');
                if (langCode) {
                    let lang = this.props.languages.available.find(l => l.lang === langCode.toLowerCase());
                    if (lang && this.props.languages.selected.lang !== langCode.toLowerCase()) {
                        this.props.changeLanguage(lang)
                    }
                }
            }
            if (queryParams.has('pid')) {
                priceId = queryParams.get('pid');
            }
        }

        if (clinicianId !== "") {
            this.setState({wait: true})
            if (this.props.clinician?.id !== clinicianId) {
                //store.dispatch(fetchProvider(clinicianId))
                this.props.fetchProvider(clinicianId).then((clinician) => {
                    if (clinician && priceId !== "") {
                        this.fetchPrice(clinicianId, priceId);
                    } else {
                        this.setState({wait: false})
                    }
                })
            } else {
                if (priceId) {
                    this.fetchPrice(clinicianId, priceId)
                } else {
                    this.setState({wait: false})
                }
            }
        }

        if (this.props.location.state?.futureAppointment) {
            this.setState({showExistingAppointmentPopup: {...this.props.location.state.futureAppointment}});
        }
    }

    fetchPrice = (clinicianId, priceId) => {
        providerService.fetchPrice(clinicianId, priceId).then(price => {
            if (price) {
                this.onChangeValue({target: {value: JSON.stringify(price)}});
            } else {
                this.setState({wait: false})
            }
        })
    }

    onChangeValue = async event => {
        let eventValue = JSON.parse(event.target.value);
        this.setState({selectedExamination: eventValue})
        await this.props.fetchFirstHour(this.props.clinician.id, eventValue.location_type, eventValue.organization_id ? eventValue.organization_id : null, eventValue.id);
        await this.props.fetchTimetable(this.props.clinician.id, eventValue.location_type, eventValue.organization_id ? eventValue.organization_id : null, eventValue.id);
        let firstHourAvailable = this.getFirstAvailableTime(this.props.firstHour.start);
        this.onSelectDate(firstHourAvailable, false)
        for (const [key, value] of Object.entries(this.props.timeTableEntries)) {
            if (eventValue.organization_id == null) {
                this.setState({showDropDown: true})
                this.setState({timetableData: value}, () => {
                    this.setState({
                        buttonsDisabled: false, firstHourAvailable: firstHourAvailable,
                        todaySlots: this.getFilteredSlots(firstHourAvailable), selectedDropDownValue: key,
                    });
                })
                break;
            }
            if (eventValue.organization_id === key) {
                this.setState({showDropDown: false})
                this.setState({timetableData: value}, () => {
                    this.setState({
                        buttonsDisabled: false,
                        firstHourAvailable: firstHourAvailable,
                        todaySlots: this.getFilteredSlots(firstHourAvailable)
                    });
                })
                break;
            }
        }
        this.setState({wait: false})

    }

    getSpecialties = (clinician) => {
        if (clinician.specialties !== undefined) {
            let specialtiesList = clinician.specialties.map((h, idx) => {
                return <div key={idx} className='clinicians-specialties-list'>{$$(h.toLowerCase())}</div>
            });

            return <div className='text-center clinicians-list-cell'>
                <div className='row' style={{'justifyContent': 'left'}}>{specialtiesList}</div>
            </div>;
        }
    }

    /**
     * Filters the slots based on the selected date
     *
     * @returns {Array} the list of filtered slots
     */
    getFilteredSlots = (date) => {
        let dateToFilter = date ? date : this.state.chosenDate;
        let todaySlots = null;
        if (this.state.timetableData != null && this.state.timetableData.data != null) {
            for (let i = 0; i < this.state.timetableData.data.length; i++) {
                if (isSameDay(moment(this.state.timetableData.data[i].date).toDate(), dateToFilter)) {
                    todaySlots = this.state.timetableData.data[i].slots
                    break;
                }
            }
        }
        return todaySlots;
    }

    getOrganizationName(organizationId, nameOnly) {
        let organizationName = "";
        if (organizationId == null) return organizationName;
        this.props.clinician.organizations.forEach((organization) => {
            if (organization.id === organizationId) {
                organizationName = organization.name;
            }
        })
        if (organizationName !== "") {
            return nameOnly ? organizationName : ", " + organizationName;
        }
        return organizationName;
    }

    getOrganizationOptions(options) {
        return options && options.map(o => {
            return {value: JSON.stringify(o), text: o.name}
        });
    }

    getExaminations = (clinician) => {
        if (clinician.prices !== undefined) {
            let pricesList = clinician.prices.map((h, idx) => {
                const orgId = h.organization_id ? h.organization_id : null;
                return <label className="row mb-4 w-100  custom-control custom-radio" key={idx}>
                    <input type="radio" className="col-1 custom-control-input" value={JSON.stringify(h)} name="prices"
                           onChange={this.onChangeValue}/>
                    <div className="col-12 row w-100  custom-control-label" style={{marginLeft: "3px"}}>
                        <div className="col-8">
                            <div
                                className="row"> {appointmentUtils.appointmentType(h)}</div>
                            <div className="row price-type">
                                {h.encounter_price_type === 'TEXT_ONLY' ? $$("text_consultation") + this.getOrganizationName(orgId) : $$(h.location_type.toLowerCase()) + this.getOrganizationName(orgId)}</div>
                        </div>
                        <div className="col-4 text-right">{formatUtils.currencyFormat(h.price_cents, h.currency)}</div>
                    </div>
                </label>
            });
            return <div className='mt-2'>
                <h6 className='mb-3'>{$$("select_appointment_type_message")}</h6>
                <div className="pl-3 pr-3">{pricesList}</div>
            </div>;
        }
    }

    /**
     * An event handler triggered when a new date is choosen
     * from the datepicker
     *
     * @param {Date} date - the new choosen date
     * @param  triggeredFromBtn - triggeredFromBtn
     */
    onSelectDate = (date, triggeredFromBtn) => {
        this.setState({chosenDate: date, triggeredFromBtn: triggeredFromBtn, todaySlots: this.getFilteredSlots(date)});
    };

    /**
     * An event handler triggered when 'Today' button is pressed
     *
     */
    selectToday = () => {
        this.onSelectDate(new Date(), true);
    }

    onSelectChange = async e => {
        let organization = JSON.parse(e.value);
        this.setState(
            {
                selectedDropDownValue: organization
            }
        )
        await this.props.fetchFirstHour(this.props.clinician.id, this.state.selectedExamination.location_type, organization.id, this.state.selectedExamination.id);
        await this.props.fetchTimetable(this.props.clinician.id, this.state.selectedExamination.location_type, organization.id, this.state.selectedExamination.id);
        for (const [key, value] of Object.entries(this.props.timeTableEntries)) {
            if (key === organization.id) {
                this.setState({timetableData: value}, () => {
                    let firstHourAvailable = this.getFirstAvailableTime(this.props.firstHour.start);
                    this.onSelectDate(firstHourAvailable, false)
                    this.setState({
                        buttonsDisabled: false,
                        firstHourAvailable: firstHourAvailable,
                        todaySlots: this.getFilteredSlots(firstHourAvailable)
                    });
                })
                break;
            }
        }
    }

    getFirstAvailableTime(o) {
        return o ? moment(o) : o;
    }

    getSpecialtiesForPanel = (clinician) => {
        return clinician.specialties.map((specialty, index) => {
            if (index === 0) return $$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase()
            else return ", " + ($$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase())
        })
    }

    getOrganizationsForPanel = (clinician) => {
        return clinician.organizations.map((organization, index) => {
            if (index === 0) return organization.name;
            else return ", " + organization.name;
        })
    }

    getClinicianTop = () => {
        return <div className="clinician-top">
            <div className='clinician-img-new-container'>
                <UserImage
                    clinician={true}
                    userID={this.props.clinician.id}
                    classnames={"clinician-img-new"}/>
            </div>
            <div className="">
                <div className="mt-2 pl-2 clinician-panel-data-container">
                    <ClinicianName
                        className={"clinician-name-clinician-page"}
                        clinician={this.props.clinician}
                        i18n={this.props.i18n}
                    />
                </div>
                <div
                    className="mt-1 clinician-specialties-clinician-page ml-2">{this.getSpecialtiesForPanel(this.props.clinician)}</div>
                <div className="mt-1 ml-2">{this.getOrganizationsForPanel(this.props.clinician)}</div>
                {isLoggedIn() &&
                    <div className="mt-2  ml-2"
                         style={{display: "flex", justifyContent: "flex-start", fontSize: "12px"}}>
                        <a href={this.props.clinician.phone_numbers ? "tel://" + this.props.clinician.phone_numbers[0] : null}>
                            <i className="fa fa-phone"/>
                            <span className="ml-1">{this.props.clinician.phone_numbers[0]}</span>
                        </a>
                        {this.props.clinician.contact_email && <a className="ml-4"
                                                                  href={"mailto:" + this.props.clinician.contact_email}>
                            <i className="fa fa-envelope"/>
                            <span className="ml-1">{this.props.clinician.contact_email}</span>
                        </a>
                        }
                    </div>
                }
            </div>
        </div>
    }

    getClinicFromSearchParams = () => {
            let orgId = "";
            const queryParams = new URLSearchParams(this.props.location.search)
            if (queryParams.has('org')) {
                orgId = queryParams.get('org');
            }
            return orgId
    }

    getOrgName = () => {
        for(let i in this.props.clinician.organizations){
            if(this.props.clinician.organizations[i].id === this.getClinicFromSearchParams()){
                return _$$("back_to_hospital_with_name", this.props.clinician.organizations[i].name)
            }
        }
        return $$('back_to_hospital')
    }

    bookTextConsultation = (responseTime) => {

        let currentDate = new Date();
        let currentDateinMs = currentDate.getTime();

        let endTime = responseTime * 60 * 60 * 1000

        history.push(
            {
                pathname: Routes.APPOINTMENT,
                state: {
                    consultationPatientDeadlineHours: responseTime,
                    text_only: true,
                    starts: currentDateinMs,
                    ends: currentDateinMs + endTime,
                    selectedPrice: this.state.selectedExamination,
                    selectedSlot: {
                        start: currentDateinMs,
                        ends: currentDateinMs + endTime,
                        location_type: this.state.selectedExamination.location_type
                    },
                    clinician: this.props.clinician,
                    selectedOrganization: this.state.selectedDropDownValue,
                    userWasntLoggedIn: this.props.selectedUser.id === undefined
                }
            }
        );
    }

    getExaminationsMode = () => {
        return <>
            <div className="examinations-label">
                <span className="ml-2"><h5>{$$('examinations_label')}</h5></span>
            </div>
            <div className="high-shadow-container">
                {this.getExaminations(this.props.clinician)}
            </div>
        </>
    }
    getCreateAppointmentMode = () => {
        let isThisYear = this.state.firstHourAvailable ? moment(this.state.firstHourAvailable).format("YYYY") === moment().format("YYYY") : true;
        return <>
            <div className="examinations-label">
                <span
                    className="ml-2"> <h5>{this.state.selectedExamination.encounter_price_type === "TEXT_ONLY" ? $$("make_a_text_consultation_label") : $$("make_an_appointment_label")}</h5></span>
            </div>
            <div className="high-shadow-container">
                {this.getChosenExamination()}
                {this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" &&
                    <div className="mt-3 flex-space-between">
                        <div className="early-appointment">
                            <div className="early-appointment-icon">
                                <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                            </div>
                            {this.state.firstHourAvailable &&
                                <div className="early-appoitment-time">
                                    <div className="small-gray-text">{$$("first_available_hour_label")}</div>
                                    <div
                                        className="first-time-available">{this.state.firstHourAvailable ? this.getDisplayForFirstHourAvailable(isThisYear) : ""}</div>
                                </div>
                            }
                            {
                                !this.state.firstHourAvailable && <div className="early-appoitment-time">
                                    {$$("no_hours_available_primary_label")}
                                </div>
                            }
                        </div>
                        {this.state.firstHourAvailable && <button className="btn btn-primary btn-first-time"
                                                                  disabled={this.state.buttonsDisabled}
                                                                  onClick={
                                                                      async () => {
                                                                          history.push(
                                                                              {
                                                                                  pathname: Routes.APPOINTMENT,
                                                                                  state: {
                                                                                      selectedPrice: this.state.selectedExamination,
                                                                                      selectedSlot: this.props.firstHour,
                                                                                      userWasntLoggedIn: this.props.selectedUser.id === undefined,
                                                                                      clinician: this.props.clinician,
                                                                                      selectedOrganization: this.state.selectedDropDownValue
                                                                                  }
                                                                              }
                                                                          );
                                                                      }
                                                                  }>
                            {$$("book_label")}
                        </button>
                        }
                    </div>}


                <CenteredModal
                    show={!!this.state.showExistingAppointmentPopup}
                    onConfirm={() => {
                        this.setState({showExistingAppointmentPopup: undefined});
                    }}
                    onHide={() => {
                        this.setState({showExistingAppointmentPopup: undefined});
                    }}
                    secondary
                    confirmBtnLabel={$$("close_btn_label")}
                >
                    <div className="successful-appointment-container">
                        {this.state.showExistingAppointmentPopup && <div className="text-danger">
                            {_$$("already_existing_appointment_body", formatDate(this.state.showExistingAppointmentPopup.startsAt))}
                        </div>}
                    </div>

                </CenteredModal>

                {!this.state.buttonsDisabled && this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" && this.state.timetableData &&
                    <div>
                        {this.state.showDropDown && <Select
                            name="organization"
                            options={this.getOrganizationOptions(this.props.clinician.organizations)}
                            value={this.props.selectedDropDownValue}
                            onChange={this.onSelectChange}
                            placeHolder={$$('organization_label')}/>
                        }
                        {this.state.firstHourAvailable &&
                            <>
                                <div className="mt-5">
                                    <ReactHorizontalDatePicker
                                        bookAppointmentPanel={true}
                                        selectedDay={this.onSelectDate}
                                        enableScroll={true}
                                        enableDays={365}
                                        i18n={this.props.i18n.selected.lang}
                                        chosenDate={this.state.chosenDate}
                                        triggeredFromBtn={this.state.triggeredFromBtn}
                                        slots={this.state.timetableData}
                                        selectToday={this.selectToday}/>
                                </div>
                                <FreeSlotsList
                                    slots={this.state.todaySlots}
                                    clinician={this.props.clinician}
                                    selectedUser={this.props.selectedUser}
                                    selectedPrice={this.state.selectedExamination}
                                    setShowExistingAppointmentPopup={(a) => this.setState({showExistingAppointmentPopup: a})}
                                />
                            </>
                        }
                    </div>
                }

                {this.state.selectedExamination.encounter_price_type === "TEXT_ONLY" && <>
                    <br/>

                    <h6 className='mb-3'>
                        {this.state.selectedExamination.description}
                    </h6>

                    <h6 className='mb-3 text-danger'>
                        <i className="far fa-clock mr-2"/>
                        {$$("clinician_responds_in") +
                            this.state.selectedExamination.average_response_time_hours +
                            " " +
                            $$("hours")}
                    </h6>

                    <h6 className='mb-3'>
                        {$$("text_only_response_explanation")}
                    </h6>

                    <h6 className='mb-3'>
                        {$$("text_only_payment_explanation")}
                    </h6>

                    <ResponseTimeButton
                        onClick={this.bookTextConsultation}
                        responseTime={24}
                        label={$$("response_in_24")}
                    />
                    <ResponseTimeButton
                        onClick={this.bookTextConsultation}
                        responseTime={48}
                        label={$$("response_in_48")}
                    />
                    <ResponseTimeButton
                        onClick={this.bookTextConsultation}
                        responseTime={720}
                        label={$$("response_no_restriction")}
                    />
                </>}
            </div>
        </>
    }

    getDisplayForFirstHourAvailable(isThisYear) {
        let date = moment(this.state.firstHourAvailable);
        let dateString = isThisYear ? date.locale(this.props.i18n.selected.lang).format("D MMM, ") : moment(this.state.firstHourAvailable).locale(this.props.i18n.selected.lang).format("D MMM, YYYY ");
        return dateString + CONVERTER.getEntryDateTimeFormat(date, false);
    }

    getChosenExamination = () => {
        const orgId = this.state.selectedExamination.organization_id ? this.state.selectedExamination.organization_id : null
        return <div className='mt-2'>
            <div className="lower-shadow-container row">
                <div className="col-2" style={{marginTop: "0.8rem"}}>
                    <div className="outer-circle">
                        <div className="inner-circle">
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="row w-100">
                        <div className="col-7">
                            <div
                                className="row"> {appointmentUtils.appointmentType(this.state.selectedExamination)}</div>
                            <div className="row">
                                {this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" && $$(this.state.selectedExamination.location_type.toLowerCase())}
                                {this.getOrganizationName(orgId, this.state.selectedExamination.encounter_price_type === "TEXT_ONLY")}
                            </div>
                        </div>
                        <div className="col-4" style={{
                            display: "flex",
                            alignSelf: "center"
                        }}>{formatUtils.currencyFormat(this.state.selectedExamination.price_cents, this.state.selectedExamination.currency)}</div>
                        <div style={{display: "flex", alignSelf: "center", cursor: "pointer"}} onClick={() => {
                            this.setState({buttonsDisabled: true})
                        }} className="col-1">
                            <i className="fa fa-times"/>
                        </div>
                    </div>
                    <div className="row pt-1">
                        <AdditionalInfo
                            examination={this.state.selectedExamination}
                            clinician={this.props.clinician}
                            i18n={this.props.i18n}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        if (!this.props.clinician.id) {
            return <div className="d-flex align-items-center w-100 h-75">
                <div className="med-search-loader"/>
            </div>
        }
        return (
            <div className="pb-3">
                {isLoggedIn() &&
                    <div className="mt-5 ml-3 back-to-clinicians pointer" onClick={() => {
                        history.push(Routes.CLINICIANS)
                    }}>
                        <span><i className="fa fa-arrow-left"/></span>
                        <span className="ml-2">{$$('back_to_clinicians_label')}</span>
                    </div>
                }

                {!isLoggedIn() && this.getClinicFromSearchParams() !== "" &&
                    <div className="mt-5 ml-3 back-to-clinicians pointer" onClick={() => {
                        history.push(Routes.CLINIC_NOT_LOGGED_IN + "?org=" +  this.getClinicFromSearchParams())
                    }}>
                        <span><i className="fa fa-arrow-left"/></span>
                        <span className="ml-2">{this.getOrgName()}</span>
                    </div>
                }

                <div className="pt-3 pl-3 pr-3">
                    {this.getClinicianTop()}
                    <hr/>
                </div>
                <div className="d-flex clinician-flex-wrap" style={{justifyContent: "flex-start"}}>
                    <div className="clinician-first-col">

                        {/*{this.getSpecialties(this.props.clinician)}*/}
                        <div className="ml-3 back-to-clinicians">
                            <span className="">{$$('biography_label')}</span>
                        </div>
                        <div className="mt-1 ml-3">
                            <ReactMarkdown>{this.props.clinician.other_info || ""}</ReactMarkdown>
                        </div>
                    </div>
                    <div className="clinician-second-col">
                        {this.state.buttonsDisabled && !this.state.wait && this.getExaminationsMode()}
                        {!this.state.buttonsDisabled && this.getCreateAppointmentMode()}
                    </div>
                </div>
            </div>
        )
    }
}

ClinicianPage.propTypes = {
    clinician: PropTypes.object,
    fetchFirstHour: PropTypes.func,
    fetchTimetable: PropTypes.func,
    firstHour: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    selectedDropDownValue: PropTypes.any,
    languages: PropTypes.any,
    changeLanguage: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object,
    timeTableEntries: PropTypes.any
}

const mapStateToProps = (state) => ({
    clinician: state.provider.data,
    i18n: state.language,
    firstHour: state.firstAvailable.data,
    timeTableEntries: state.providerTimetable.entries,
    languages: state.language,
    selectedUser: state.selectedUser.data
})

const mapDispatchToProps = {
    fetchFirstHour,
    fetchTimetable,
    fetchProvider,
    changeLanguage
}


export default connect(mapStateToProps, mapDispatchToProps)(ClinicianPage)
