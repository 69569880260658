export const
    bg = {
        text: {
            morning_time: "Сутрешно време",
            evening_time: "Вечерно време",
            noon_time: "Обедно време",
            bed_time: "Време преди лягане",
            back_label: "Назад",
            add_profile_photo: "Качи снимка",
            congrats_message: "Поздравления",
            continue_medrec: "Продължете към Medrec-M",
            registration_success_message: "Вашият профил е създаден успешно",
            wizard_provider_image_label: "Качете Вашата снимка",
            title: "MEDREC : M",
            app: "app",
            password_creation_title:"Паролата трябва да съдържа:",
            at_Lest_eight_characters:"най-малко 8 символа",
            at_Lest_one_uppercase_characters:"поне една главна буква (A-Z)",
            at_Lest_one_lowercase_characters:"поне една малка буква (a-z)",
            at_Lest_one_number:"поне едно число (0-9)",
            at_Lest_one_special:"поне един специален знак (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
            password_is_not_valid:"Паролата е невалидна (виж изисквания за паролата)",
            main_page_label: "Медицинско досие",
            select_language_label: "Избери език",
            login_label: "Вход",
            login_form_title: "Вход",
            social_media_login_separator: "Или",
            logout_label: "Изход",
            save_btn_label: "Запази",
            username_label: "Електронна поща",
            email_label: "Електронна поща",
            password_label: "Парола",
            new_password_label: "Нова парола",
            repeat_password_label: "Повтори паролата",
            change_password_label: "Промяна на паролата",
            user_password_updated_message: 'Паролата е актуализирана успешно',
            user_password_update_error_message: 'Сървърна грешка',
            email_required_message: "Моля, въведете електронната си поща",
            invalid_date_format: "Невалиден формат на датата",
            invalid_time_format: "Невалиден формат на времето",
            password_required_message: "Моля, въведете парола",
            placeholder_email_message: "Въведете електронна поща",
            placeholder_password_message: "Въведете парола",
            forgotten_password: "Забравена парола?",
            agreements_message: "Прочел съм и съм съгласен с ",
            agreements_message_ca: "\"Условията за ползване\".",
            login_form_invalid_credentials_message: "Невалидни поща/парола",
            register_label: "Регистрация",
            fullname_label: "Име",
            male_label: "Мъж",
            female_label: "Жена",
            register_form_password_confirm_label: "Потвърди парола",
            register_form_email_exists_message: "Електронната поща вече е регистрирана",
            register_form_email_not_correct_message: "Електронната поща е грешна",
            register_form_passwords_not_match_message: "Паролите не съвпадат",
            register_form_password_length_message: "Паролата трябва да е поне 8 символа",
            register_form_password_whitespace_message: "Паролата не може да започне или да завърши с интервали",
            register_form_full_name_required: "Моля, въведете вашите имена",
            register_form_password_confirm_required_message: "Потвърдете паролата",
            register_form_successful_message: "Потребителят е създаден",
            confirm_btn_label: "Потвърди",
            close_btn_label: "Затвори",
            close_search: "Затвори",
            Charts: "Графики",
            Logbook: "Дневник",
            logbook_entries_header: " ",
            delete_log_entry_modal_header: "Потвърдете действието",
            delete_log_entry_modal_text: "Сигурни ли сте, че искате да изтриете този запис?",
            select_entry_type_error: "Моля, изберете първо тип на записа",
            glucose_units: "Мерни единици за кръвна захар",
            ketones_units: "Мерни единици за кетони",
            hba1c_units: "Мерни единици за HbA1c",
            Systolic: "Систолично",
            Diastolic: "Диастолично",
            Pulse: "Пулс",
            notes: "Бележки",
            conclusion:"Заключение",
            preliminary_diagnosis:"Предварителна диагноза",
            appointment_notes_title:"Съобщения",
            appointment_note_title:"Съобщение",
            total_cholesterol: "Общ холестерол",
            triglycerides: "Триглицериди",
            Cholesterol: "Холестерол",
            cholesterol: "Холестерол",
            Weight: "Тегло",
            Height: "Височина",
            weight_and_height: "Тегло и височина",
            chronic_conditions: "Хронични заболявания",
            allergies: "Алергии",
            blood_group: "Кръвна група",
            details: "Детайли",
            blood_pressure: "Кръвно налягане",
            settings_label: "Настройки",
            unit_of_measurement_label: "Мерни единици",
            cholesterol_units_label: "Мерна единица на холестерола",
            classification_method_label: "Класификация",
            date_format_label: "Формат на датата",
            time_24hour_label: "Използвай 24 часов формат",
            settings_updated_message: "Настройките са запазени успешно",
            US: "САЩ (lbs, oz, fl oz, inch)",
            metric: "Метрична (kg, gram, ml, cm)",
            kg: "кг",
            pound: "фунт(а)",
            inches: "инча",
            cm: "см",
            None: "Няма",
            diagnosed_in: "Диагностициран на",
            mild: "Лека",
            mild_to_moderate: "Лека до умерена",
            moderate: "Умерена",
            moderate_to_severe: "Умерена до тежка",
            severe: "Тежка",
            life_threatening: "Животозастрашаваща",
            drug_allergy: "Медикаментозна алергия",
            dust_allergy: "Алергия към домашен прах",
            food_allergy: "Хранителна алергия",
            insect_allergy: "Алергия към насекоми",
            latex_allergy: "Алергия към латекс",
            mold_allergy: "Алергия към плесени",
            pet_allergy: "Алергия към животни",
            percent: "%",
            millimol_per_mol: "ммол/мол",
            pollen_allergy: "Алергия към полени",
            other_allergy: "Друга алергия",
            Severity: "Степен",
            Medications: "Лекарства",
            Medication_plans: "Лекарствен план",
            millimol_per_litre: "ммол/л",
            milligram_per_decilitre: "mg/dL",
            millimetre_of_mercury: "mmHg",
            days_between_intake: "Ден на прием",
            days: "дни",
            every: "Всеки",
            no_unread_messages: "Няма непрочетени чат-съобщения",
            search_within_selected_messages_label: "Търсете в чат-съобщенията си",
            no_users_after_filtering:"Няма върнати резултати",
            sort_by_name:"Сортирай по име",
            of: "от",
            sort_by_most_recent:"Сортирай по време",
            new_messages_only:"Само непрочетени чат-съобщения",
            number_times_per_day: "Брой приеми на ден",
            number_of_days: "Брой дни",
            today: "Днес",
            auto_logout:"Вашата сесия изтече",
            yesterday:"Вчера",
            week_label: "7 дни",
            fortnight_label: "14 дни",
            month_label: "30 дни",
            quarter_label: "90 дни",
            custom_label: "Персонален",
            symptoms: "Симптоми",
            Temperature: "Температура",
            Hydration: "Хидратация",
            Respiratory_rate: "Дихателна честота",
            Saturation: "Насищане с кислород",
            celsius: "°C",
            ml: "ml",
            bpm: "bpm",
            fahrenheit: "°F",
            fl_oz: "fl oz",
            refresh_token_expired_message: 'Изтекла сесия',
            sharing_label: "Споделяне",
            clinicians_label: "Лекари",
            no_clinicians_primary_label: "Няма намерени лекари",
            no_clinicians_secondary_label: "Не са намерени лекари, отговарящи на Вашето търсене",
            all_clinicians: "Всички лекари",
            waiting_for_approval_list_label: "Очакващи Вашето одобрение",
            access_links_label: "Временни връзки за достъп",
            shared_with_list_label: "Споделени със",
            deny_permission_label: "Откажи",
            approve_permission_label: "Разреши",
            share_data_label: "Сподели данни",
            new_link_label: "Нова връзка",
            share_label: "Сподели",
            valid_to_label: "Валидна до",
            requested_user_does_not_exist_message: "Не може да бъде намерен потребител с този имейл.",
            share_data_success_message: "Заявката е успешно изпратена.",
            forgot_password: "Забравена парола",
            privacy_policy: "Политика за сигурност на личните данни",
            gender_label: "Пол",
            country_label: "Страна",
            male: "Мъж",
            female: "Жена",
            city_label: "Град",
            birthday_label: "Рождена дата",
            dashboard: "Основен панел",
            user_info_label: "Персонална информация",
            latest_measurements_label: "Последни записи в дневника",
            last_week_data: "Седмични графики",
            no_recent_measurements: "Няма намерени записи.",
            loading_data_label: "Зареждане...",
            logbook_no_data_label: "Потребителят няма записани измервания.",
            symptoms_no_data_label: "Потребителят няма записани симптоми.",
            search_placeholder_label: "Търсене...",
            latest_symptoms_label: "Симптоми от последните 24 часа",
            no_latest_symptoms_label: "Няма намерени симптоми от последните 24 часа.",
            profile_label: 'Профил',
            profile_tab: 'Основен профил',
            edit_profile_label: 'Редактиране на профила',
            info_required:"Задължителни данни",
            valid_phone_required: "Телефонният номер е невалиден",
            info_required_phone_explanation:"Моля, въведете име и телефонен номер, на който може да бъдете открити от клиниката или лекаря в случай на необходимост. Тези данни ще бъдат записани в полетата 'Име' и 'Телефон' в Medrec:M профила на %1.",
            update_btn: 'Промени',
            upload_image_label: 'Качване на профилна снимка',
            user_info_updated_message: 'Профилът е променен',
            unsupported_image_format: 'Този файлов формат не се поддържа',
            choose_file_label: 'Избери снимка',
            documents: "Документи",
            category_label: "Категория",
            document_type_label: "Тип документ",
            all_document_types_label: "Всички документи",
            type_description_label: "Описание на типа",
            document_download_dialog_title: "Свали документ",
            confirmation_dialog_message: "Документът ({fileName}), който искате да свалите принадлежи на {owner} и може да съдържа лична информация. Съхранявайте документа на сигурно място или го изтрийте след прочитането му.",
            documents_no_data_label: "Този потребител няма качени документи.",
            download_btn: "Свали",
            download_as_pdf: "Свали резултатите от прегледа на файл",
            download_as_pdf_consultation: "Свали резултатите от консултацията на файл",
            chat_disabled_message: "Чат-съобщенията към този лекар в момента са изключени!",
            chat_disabled_message_with_unlock_time: "Чат-съобщенията към този лекар ще бъдат отключени на %1!",
            examination_outcome:"Резултат от преглед",
            cancel_btn: "Откажи",
            document_type_none: "Без",
            document_type_epicrisis: "Епикриза",
            document_type_prescription: "Рецепта",
            lab_name: "Име на лаборатория",
            document_type_lab_results: "Лабораторни резултати",
            document_type_ambulance_sheet: "Амбулаторен лист",
            document_type_referral: "Направление",
            document_type_other: "Друго",
            document_type_diagnostic_imaging: "Образна диагностика",
            all_label: "Всички",
            select_category: "Избери категория",
            documents_no_data_for_filters_label: "Няма намерени документи отговарящи на избраните критерии.",
            pending_for_auth_approval_label: "Потребители, които трябва да приемат вашата покана",
            start_date: "От дата",
            end_date: "До дата",
            add_entry: "Добавете запис",
            upgrade_child: "надгради към главен профил",
            upgrade: "надгради",
            to_regular: "към главeн профил",
            confirm_password_label: "потвърди парола",
            passwords_dont_match: "Паролите не съвпадат",
            password_not_long_enough: "Паролата трябва да е поне 8 символа",
            password_whitespace_message: "Паролата не може да започне или да завърши с интервали",
            confirm_upgrade: "на път сте да актуализирате профила си като основен",
            upgrade_advisory: "След това действие, ще бъдете пренасочени  към таблото и вече няма да можете да управлявате",
            upgrade_continue: "сигурни ли сте, че искате да продължите?",
            update_entry: "Обновете запис",
            entry_types: "Всички записи",
            entry_type_temperature: "Температура",
            entry_type_blood_pressure: "Кръвно налягане",
            entry_type_blood_sugar: "Кръвна захар",
            entry_type_hba1c: "HbA1c",
            hba1c: "HbA1c",
            entry_type_ketones: "Кетони",
            entry_type_urine_ph: "PH на урина",
            entry_type_weight: "Тегло",
            entry_type_height: "Ръст",
            entry_type_cholesterol: "Холестерол",
            entry_type_oxygen_saturation: "Кислородна сатурация",
            entry_type_respiratory_rate: "Дихателна честота",
            respiratory_rate: "Дихателна честота",
            hydration: "Прием на течности",
            entry_type_hydration: "Прием на течности",
            entry_type_menstruation: "Менструация",
            menstruation: "Менструация",
            entry_type_medication: "Лечение",
            position_type_seated: "Седнал",
            position_type_lying: "Легнал",
            position_type_standing: "Прав",
            measuring_site_type_left_arm: "Лява ръка",
            measuring_site_type_right_arm: "Дясна ръка",
            measuring_site_type_left_wrist: "Лява китка",
            measuring_site_type_right_wrist: "Дясна китка",
            measuring_site_type_left_thigh: "Ляво бедро",
            measuring_site_type_right_thigh: "Дясно бедро",
            measuring_site_type_left_calf: "Ляв прасец",
            measuring_site_type_right_calf: "Десен прасец",
            measuring_site_type_left_ankle: "Ляв глезен",
            measuring_site_type_right_ankle: "Десен глезен",
            temperature_label: "Температура",
            temperature: "Температура",
            systolic_label: "Систолично",
            diastolic_label: "Диастолично",
            pulse_label: "Пулс",
            position_label: "Позиция",
            measuring_site_label: "Измерване от",
            arrhythmia_detected_label: "Установена е аритмия",
            blood_sugar_label: "Кръвна захар",
            blood_sugar: "Кръвна захар",
            hba1c_label: "HbA1c",
            ketones_label: "Кетони",
            ketones: "Кетони",
            urine_ph_label: "pH на урина",
            urine_ph: "pH на урина",
            weight_label: "Тегло",
            weight: "Тегло",
            height_label: "Ръст",
            height: "Ръст",
            total_cholesterol_label: "Общ холестерол",
            total_label: "Общ",
            ldl_label: "LDL",
            hdl_label: "HDL",
            triglycerides_label: "Триглицериди",
            saturation: "Кислородна сатурация",
            respiratory_rate_label: "Дихателна честота",
            hydration_label: "Прием на течности",
            flow_label: "Течение",
            color_label: "Цвят",
            primary_colour: "Основни цветове",
            secondary_colour: "Допълнителни цветове",
            select_medication_style: "Избери стил на лекарство",
            consistency_label: "Плътност",
            medication_name_label: "Име на лекарството",
            unit_label: "Единица",
            dose_label: "Доза",
            notes_label: "Съобщения",
            datetime_required_message: "Датата и времето не са въведени",
            date_required_message: "Датата не е въведена",
            datetime_max_value_message: "Датата и времето не може да са бъдещето",
            end_date_before_start_date: "Крайната дата не може да бъде преди началната",
            temperature_required_message: "Температурата не е въведена",
            temperature_wrong_format_message: "Форматът на температурата е невалиден",
            temperature_out_of_range_message: "Стойността на температурата е невалидна",
            blood_pressure_required_message: "Кръвно налягане или пулс са задължителни полета",
            blood_pressure_wrong_format_message: "Форматът на стойностите за кр. налягане е невалиден",
            blood_pressure_out_of_range_message: "Стойностите за кр. налягане са невалидни",
            blood_sugar_required_message: "Стойността на кр. захар не е въведена",
            blood_sugar_wrong_format_message: "Форматът на кр. захар е невалиден",
            blood_sugar_out_of_range_message: "Стойността на кр. захар е невалидна",
            hba1c_required_message: "Стойността на HbA1c не е въведена",
            hba1c_wrong_format_message: "Форматът на HbA1c е невалиден",
            hba1c_out_of_range_message: "Стойността на HbA1c е невалидна",
            ketones_required_message: "Стойността на кетоните не е въведена",
            ketones_wrong_format_message: "Форматът на кетоните е невалиден",
            ketones_out_of_range_message: "Стойността на кетоните е невалидна",
            urine_ph_required_message: "Стойността на pH на урината не е въведена",
            urine_ph_wrong_format_message: "Форматът на pH на урината е невалиден",
            urine_ph_out_of_range_message: "Стойността на pH на урината е невалидна",
            weight_required_message: "Стойността на теглото не е въведена",
            weight_wrong_format_message: "Форматът на теглото е невалиден",
            weight_out_of_range_message: "Стойността на теглото е невалидна",
            height_required_message: "Стойността на ръста не е въведена",
            height_wrong_format_message: "Форматът на ръста е невалиден",
            height_out_of_range_message: "Стойността на ръста е невалидна",
            cholesterol_required_message: "At least one of the cholesterolfields must be filled",
            total_cholesterol_wrong_format_message: "Форматът на общия холестерол е невалиден",
            total_cholesterol_out_of_range_message: "Стойността на общия холестерол е невалидна",
            ldl_wrong_format_message: "Форматът на LDL е невалиден",
            ldl_out_of_range_message: "Стойността на LDL е невалидна",
            hdl_wrong_format_message: "Форматът на HDL е невалиден",
            hdl_out_of_range_message: "Стойността на HDL е невалидна",
            triglycerides_wrong_format_message: "Форматът на триглицеридите е невалиден",
            triglycerides_out_of_range_message: "Стойността на триглицеридите е невалидна",
            oxygen_saturation_required_message: "Стойността на кислородната сатурация не е въведена",
            oxygen_saturation_wrong_format_message: "Форматът на кислородната сатурация е невалиден",
            oxygen_saturation_out_of_range_message: "Стойността на кислородната сатурация е невалидна",
            respiratory_rate_required_message: "Стойността на дихателната честота не е въведена",
            respiratory_rate_wrong_format_message: "Форматът на дихателната честота е невалиден",
            respiratory_rate_out_of_range_message: "Стойността на дихателната честота е невалидна",
            hydration_required_message: "Хидратация е задължително поле",
            hydration_wrong_format_message: "Невалидна стойност за полето Хидратация",
            hydration_out_of_range_message: "Стойността на полето Хидратация е извън допустимите стойности ",
            medication_name_required_message: "Име на лекарство е задължително поле",
            unit_required_message: "Мерна единица е задължително поле",
            dose_required_message: "Доза е задължително поле",
            dose_wrong_format_message: "Невалидна стойност за полето Доза",
            dose_out_of_range_message: "Стойността на полето Доза е извън допустимите стойности",
            load_more: "Покажи следващите",
            afternoon: "Следобед",
            morning: "Сутрин",
            evening: "Вечер",
            select_gender: "Избери пол",
            my_documents_label: "Моите документи",
            my_children_label: "Моите деца",
            HBA1C: "HbA1c (Гликиран хемоглобин)",
            Ketones: "Кетони",
            Flow: "Течение",
            Color: "Цвят",
            Consistency: "Консистенция",
            Urine_pH: "pH на урина",
            not_entered: "Не е въведено",
            light: "Слабо",
            medium: "Средно",
            heavy: "Силно",
            unexpected: "Неочаквано",
            none: "Няма",
            black: "Черен",
            brown: "Кафяв",
            dark_red: "Тъмно червен",
            bright_red: "Ярко червен",
            pink: "Розов",
            orange: "Оранжев",
            gray: "Сив",
            watery: "Водниста",
            clots: "Съсиреци",
            mmol_mol: "ммол/мол",
            no_chronic_conditions: "Няма хронични заболявания",
            no_allergies: "Няма алергии",
            Communication: "Съобщения",
            my_communication_label: "Моите съобщения",
            new_chat: "Нов разговор",
            recent_chats_label: "Последни разговори",
            available_users_label: "Достъпни потребители",
            back: "Назад",
            back_to_hospitals: "Назад към здравни заведения",
            back_to_hospital: "Назад към здравно заведение",
            back_to_hospital_with_name:"Назад към %1",
            type_message: "Напиши чат-съобщение...",
            call: "Входящо повикване",
            time_remaining: "до очакваното крайно време: {time}",
            time_over: "{time} след очакваното крайно време",
            incoming_call_message: "Входящо повикване от %1",
            incoming_call_another_user_message: 'Входящо повикване от друг потребител',
            accept: "Приемам",
            no_user_selected_message: 'Няма избран потребител',
            warning: 'Внимание',
            call_ending_soon: "Обаждането ще приключи скоро",
            call_ending_soon_body: "Краят на определеното време за текущия преглед наближава!",
            no_answer: "Няма отговор",
            no_answer_message: "Няма отговор от %1",
            you_wrote: "Вие написахте",
            missed_call_label: "Пропуснато обаждане",
            rejected_call_label: "Отказано обаждане",
            in_progress_call_label: "Текущ разговор",
            completed_call_label: "Приключен разговор",
            ongoing_call: "Call ongoing",
            medication_plan: "График на лекарствата",
            general_practitioner: "Общопрактикуващ лекар",
            practice_name: "Име на здравно заведение",
            practice_name_required: "Име на здравно заведение е задължително",
            practice_address: "Адрес на здравно заведение",
            practice_address_required: "Адрес на здравно заведение  е задължително",
            practice_contact: "Телефон за контакти",
            practice_contact_required: "Телефон за контакти е задължително",
            practice_centre_code: "Номер на здравно заведение",
            practice_centre_code_required: "Номер на здравно заведение е задължително",
            practice_practitioner_code: "Номер на лекар",
            practice_practitioner_code_required: "Номер на лекар  е задължително",
            na: "Не е приложимо",
            times_per_day: "%1 %2, %3 път дневно",
            times_per_day_plural: "%1 %2, %3 пъти дневно",
            every_x_hours: "%1 %2, на всеки %3 час",
            every_x_hours_plural: "%1 %2, на всеки %3 часа",
            daily: 'Всеки ден',
            every_x_days: "Всеки X дни",
            every_days: "Всеки ден",
            every_days_plural: "Всеки %1 дни",
            take_x_rest_x: "Взимане X дни и почивка Y дни",
            take_rest_x_plural: "Взимане %1 дена и почивка един ден",
            take_rest_y_plural: "Взимане един ден и почивка %2 дни",
            take_rest_xy_plural: "Взимане %1 дни и почивка %2 дни",
            take_rest: "Взимане един ден и почивка един ден",
            when_needed: "При нужда",
            ongoing: "Безсрочно",
            until_date: 'До дата',// %1',
            for_x_days_until: "За X дни",// до %2",
            for_days_until: "за 1 ден",// до %2",
            for_days_until_plural: "за %1 дни",
            specific_days_of_week: "Избрани дни от седмицата",
            effective_from: "В действие от %1",
            medical_record_label: "Медицински картон",
            Lab_Results: "Лабораторни резултати",
            select_laboratory_label: "Избери лаборатория",
            all_laboratory_label: "Всички лаборатории",
            lab_results_open_results_label: "Отвори резултатите",
            lab_results_close_results_label: "Затвори резултатите",
            lab_results_no_data_for_filters_label: "Не са намерени резултати отговарящи на избраните критерии.",
            lab_results_no_data_label: "Този потребител няма качени лабораторни резултати.",
            lab_test_col_name_name: "Група показател",
            lab_test_col_name_result: "Резултат",
            lab_test_col_name_units: "Мерни единици",
            lab_test_col_name_flag: "Флаг",
            lab_test_col_name_range: "Референтни стойности",
            lab_microbiology_col_name_antibiogram: "Антибиограма",
            select: 'Избери',
            import: "Импортирай",
            preview_image: 'Преглед',
            OK: 'Потвърждавам',
            delete_image_modal_title: 'Премахни снимка',
            delete_image_modal_message: 'Сигурни ли сте, че искате да премахнете вашата снимка?',
            add_symptom_label: "Добави симптом",
            log_symptoms_label: "Въведи симптоми",
            log_symptoms_date_label: "Дата",
            log_symptoms_time_label: "Час",
            log_symptoms_notes_label: "Бележки",
            log_symptoms_form_date_time_not_correct_message: "Избраната дата не може да бъде по-голяма от текущата",
            log_symptoms_form_date_time_required_message: "Полето дата е задължително",
            mark_symptom_entry_deleted_modal_title: "Маркирай записа за симптоми като изтрит",
            mark_symptom_entry_deleted_modal_message: "Сигурни ли сте, че искате да маркирате този запис за симптоми като изтрит?",
            mark_document_entry_deleted_modal_title: "Маркирай записа за документ като изтрит",
            mark_document_entry_deleted_modal_message: "Сигурни ли сте, че искате да изтриете този документ?",
            unit_type_ampule: "ампула(и)",
            unit_type_application: "прилагане(ия)",
            unit_type_capsule: "капсула(и)",
            unit_type_drop: "капка(и)",
            unit_type_gram: "г",
            unit_type_injection: "инжекция(ии)",
            unit_type_mg: "мг",
            unit_type_ml: "мл",
            unit_type_packet: "саше(та)",
            reset_btn_label: "Отмени",
            unit_type_patch: "пластир(и)",
            unit_type_piece: "част(и)",
            unit_type_tablet: "таблетка(и)",
            unit_type_puff: "инхалиране(ия)",
            unit_type_spray: "впръскване(ия)",
            unit_type_suppository: "супозитория(ии)",
            unit_type_tbsp: "с.л.",
            unit_type_tsp: "ч.л.",
            unit_type_unit: "единица(и)",
            create_document: "Създай документ",
            document_title: "Наименование",
            document_title_required_message: "Наименование е задължително поле",
            type_description_required_message: "Описание на типа е задължително поле",
            document_category: "Категория",
            shared_label: "Сподели",
            upload_document: "Качи документ",
            new_document: "Нов документ",
            no_file_chosen: "Не е избран файл",
            file_required_message: "Файл е задължително поле",
            obstetrics_gynecology: 'Акушерство и гинекология',
            allergology: 'Алергология',
            angiology: 'Ангиология',
            venereology: 'Венерология',
            appointment_filter_all: "Всички",
            appointment_filter_completed: "Завършени прегледи",
            appointment_filter_pending: "Предстоящи прегледи",
            appointment_filter_active_consults: "Активни консултации",
            internal_medicine: 'Вътрешни болести',
            gastroenterology: 'Гастроентерология',
            thoracic_surgery: 'Гръдна хирургия',
            dental_medicine: 'Дентална медицина',
            dermatology: 'Дерматология',
            pediatric_gastroenterology: 'Детска гастроентерология',
            pediatric_endocrinology: 'Детска ендокринология',
            pediatric_neurology: 'Детска неврология',
            pediatric_nephrology: 'Детска нефрология',
            child_psychiatry: 'Детска психиатрия',
            pediatric_pulmonology: 'Детска пулмология',
            pediatric_rheumatology: 'Детска ревматология',
            endocrinology: 'Ендокринология',
            immunology: 'Имунология',
            cardiology: 'Кардиология',
            cardiac_surgery: 'Кардиохирургия',
            clinical_genetics: 'Клинична генетика',
            speech_therapy: 'Логопедия',
            mammology: 'Мамология',
            microbiology: 'Микробиология',
            neurology: 'Неврология',
            neurological_surgery: 'Неврохирургия',
            neonatology: 'Неонатология',
            nephrology: 'Нефрология',
            nuclear_medicine: 'Нуклеарна медицина',
            imaging_diagnostics: 'Образна диагностика',
            general_medicine: 'Обща медицина',
            oncology: 'Онкология',
            orthodontics: 'Ортодонтия',
            orthopedics_traumatology: 'Ортопедия и травматология',
            otoneurology: 'Отоневрология',
            ophthalmology: 'Офталмология',
            pediatrics: 'Педиатрия',
            psychiatry: 'Психиатрия',
            psychology: 'Психология',
            psychotherapy: 'Психотерапия',
            pulmonology: 'Пулмология',
            rheumatology: 'Ревматология',
            vascular_surgery: 'Съдова хирургия',
            ent: 'УНГ',
            urology: 'Урология',
            physical_medicine_rehabilitation: 'Физикална медицина и рехабилитация',
            hematology: 'Хематология',
            surgery: 'Хирургия',
            homeopathy: 'Хомеопатия',
            specialties: "специалности",
            health_issues_label: "Здравни състояния",
            health_issues_menu_label: "Здравни състояния",
            health_issues_no_data_label: "Няма създадени здравни състояния",
            health_issues_no_data_primary_label: "Няма създадени здравни състояния",
            processing_label: "Обработване",
            my_appointments_label: 'Прегледи',
            appointments_no_data_primary_label: 'Няма предстоящи прегледи',
            no_appointments_primary_label: 'Няма предстоящи прегледи',
            waiting_for_doctor_approval: "Очаква потвърждение",
            waiting_for_doctor_approval_consultation: "Очаква потвърждение",
            waiting_for_patient_approval: "Очаква потвърждение от Вас",
            waiting_for_patient_approval_consultation: "Очаква потвърждение от Вас",
            need_more_info: "Нуждае се от повече информация",
            need_more_info_consultation: "Нуждае се от повече информация",
            accepted: "Потвърден",
            accepted_consultation: "Потвърдена",
            completed: "Завършен",
            completed_consultation: "Завършена",
            rejected_by_patient: "Отхвърлен от пациент",
            rejected_by_patient_consultation: "Отхвърлена от пациент",
            canceled_by_patient: "Отказан от пациент",
            canceled_by_patient_consultation: "Отказана от пациент",
            rejected_by_provider: "Отхвърлен от лекар",
            rejected_by_provider_consultation: "Отхвърлена от лекар",
            canceled_response_overdue:"Анулиран (Просрочен отговор)",
            canceled_response_overdue_consultation:"Анулирана (Просрочен отговор)",
            canceled_not_paid:"Анулиран (Просрочено плащане)",
            canceled_not_paid_consultation:"Анулиранa (Просрочено плащане)",
            canceled_by_provider: "Отказан от лекар",
            canceled_by_provider_consultation: "Отказана от лекар",
            cancel_appointment_confirmation_header: "Сигурни ли сте, че искате да отмените прегледа при {fullname} за {date}?",
            cancel_consultation_confirmation_header: "Сигурни ли сте, че искате да отмените писмената консултация при {fullname} създадена на {date}?",
            cancel_appointment_label: "Отмени преглед",
            cancel_consultation_label: "Отмени консултацията",
            cancel_appointment_confirmation_body: "Прегледът ще бъде отменен веднага. Това действие е необратимо",
            cancel_consultation_confirmation_body: "Консултацията ще бъде отменена веднага. Това действие е необратимо",
            consultation_deadline_time: "Крайният срок за отговор от лекаря е %1",
            consultation_follow_up_deadline_time: "Можете да пишете съобщения до %1",
            edit_label: "Редактиране",
            start_date_label: "Начална дата",
            end_date_label: "Крайна дата",
            delete_label: "Изтриване",
            primary_exam: 'Първичен преглед',
            follow_up_exam: 'Вторичен преглед',
            other: 'Друг',
            online: 'Дистанционен',
            on_site: 'На място',
            first_available_hour_label: "Запазете първия свободен час на",
            book_another_hour_label: "Изберете друг час",
            select_appointment_type_message: "Моля, изберете консултация или преглед, за да видите кои са свободните часове за него",
            make_an_appointment_label: "Запазете час",
            text_only_response_explanation: "Моля, изберете време за отговор. Времето за отговор не е гарантирано, но ако не получите отговор в указаното време, то сумата за консултацията ще бъде възстановена.",
            hours: "часа ",
            clinician_responds_in: "Този лекар отговаря до ",
            response_in_16: "Отговор до 16 часа",
            response_in_24: "Отговор до 24 часа",
            response_in_48: "Отговор до 48 часа",
            response_no_restriction: "Отговор до 30 дни",
            make_a_text_consultation_label: "Създай писмена консултация",
            contacts_label: "Контакти",
            phone_contact_label: "Обаждане: ",
            email_contact_label: "Имейл до: ",
            no_number_label: "Няма номер",
            no_email_label: "Няма имейл",
            select_today_label: 'Днес',
            import_all: "Качи всички",
            import_selected: "Качи избрано",
            eccounter_data_separator: "Резултат от прегледа",
            consultation_results_data_separator: "Резултат от консултацията",
            main_diagnosis_label: "Основна диагноза",
            objective_data_label: "Обективно състояние",
            subjective_data_label: "Анамнеза",
            therapy_label: "Терапия",
            prescriptions: "Медикаменти",
            concomitant_diseases_label: "Придружаващи заболявания и усложнения",
            tests_label: "Изследвания",
            no_encounter_found_label: "Прегледът не е приключен.",
            no_encounter_found_label_consultation: "Писмената консултация не е приключена.",
            payment_completed: "Платен",
            payment_completed_consultation: "Платена",
            payment_initial: "Неплатен",
            payment_initial_consultation: "Неплатена",
            payment_in_progress: "В процес на плащане",
            payment_in_progress_consultation: "В процес на плащане",
            payment_rejected: "Неплатен",
            payment_rejected_consultation: "Неплатена",
            create_appointment_health_issue_message: "или въведете по-долу само здравните състояния, за които се отнася прегледът. По този начин лекарят ще има достъп само до записите асоциирани с тези състояния.",
            create_appointment_notes_message: "Въведете допълнителна информация относно причините за този преглед. Опишете възможно най-подробно всички детайли.",
            select_health_issue_label: "Изберете здравни състояния",
            create_appointment: "Създай преглед",
            share_all_data_consultation_explanation: "Важно! Споделената информация ще е достъпна от лекаря докато консултацията не бъде отменена или 14 дни след създаването ѝ.",
            create_consultation_health_issue_message: "Или въведете по-долу само здравните състояния, за които се отнася консултацията. По този начин лекарят ще има достъп само до записите асоциирани с тези състояния.",
            create_consultation_notes_message: "В съобщението въведете допълнителна информация относно причините за тази консултация. Опишете възможно най-подробно всички детайли.",
            notes_required_message: "Съобщението е задължително, за да създадете писмена консултация",
            create_health_issue: "Ново здравно състояние",
            health_issue_title: "Наименование на здравното състояние",
            health_issue_description: "Описание",
            health_issue_title_required_message: "Наименованието на здравно състояние е задължително поле",
            related_label: "Свързани елементи",
            date_label: "Дата",
            mark_health_issue_entry_deleted_modal_title: "Маркирай записа за здравно състояние като изтрит",
            mark_health_issue_entry_deleted_modal_message: "Сигурни ли сте, че искате да маркирате този запис за здравно състояние като изтрит?",
            child_label: "Създайте нов свързан профил",
            messages_label: "Чат",
            recent_messages_title: "Последни чат-съобщения",
            no_messages_to_show: "Няма чат-съобщения",
            delete_connected_profile:"Изтрий споделен профил",
            delete_connected_profile_text:"Сигурни ли сте, че искате да оттеглите правото на %1 да управлява профила на %2? Това действие не може да бъде отменено.",
            delete_connected_profile_text_parent:"Сигурни ли сте, че искате да премахнете своите права да управлявате %1? Това действие не може да бъде отменено.",
            check_email:"Потребител с този имейл не може да бъде намерен",
            add_connected_profile:"Добавете свързан профил",
            add_connected_profile_info:"За да добавите свързан профил, въведете регистириран имейл във полето отдолу.",
            add_connected_profile_info_warn:"Важно: всички свързани профили ще имат пълен контрол върху този профил.",
            delete_connected_profile_text_final_parent:"Вие сте последния свързан профил за %1. Ако продължите, профилът на %1 ще бъде премахнат и всички данни ще бъдат изтрити.",
            messages_will_appear_here: "Съобщенията ще бъдат показани тук",
            connected_profiles:"Споделени профили",
            approve_child:"Одобри споделени профили",
            approve:"Одобри",
            deny:"Откажи",
            pin_required:"Изисква се ПИН",
            submission_error:"Профилът не е споделен. Проверете ПИН.",
            rejection_error:"Сървърна грешка",
            search_in_messages_label: "Търсене",
            search_for_medications: "Търсене",
            medications_found: "Намерени медикаменти",
            needs_prescription:"С лекарско предписание",
            doesnt_need_prescription:"Без лекарско предписание",
            add_allergy: "Добави алергия",
            allergen_name_label: "Име на алерген",
            date_diagnosed_label: "Дата на диагноза",
            description: "Детайли",
            medications: "Лекарства",
            phone_label: "Телефон",
            allergy_severity_label: "Сила",
            allergy_type_label: "Тип на алергията",
            edit_allergy: "Редактирай алергия",
            add_condition: "Добави хронично заболяване",
            edit_condition: "Редактирай хронично заболяване",
            allergen_name_required_message: "Име на алергена е задължително поле",
            chronic_condition_name_required_message: "Име на хроничното заболяване е задължително поле",
            create: 'Създай',
            condition_name: "Име на хронично заболяване",
            mark_allergy_entry_deleted_modal_title: "Сигурни ли сте, че искате да изтриете алергията",
            mark_condition_entry_deleted_modal_title: "Сигурни ли сте, че искате да изтриете хроничното заболяване?",
            disease_code: "Код на заболяване",
            disease: "Заболяване",
            no_results_found: "Няма намерени резултати",
            good_morning: "Добро утро",
            good_afternoon: "Добър ден",
            good_evening: "Добър вечер",
            charts: "Графики",
            last_month_vitals: "Последни измервания",
            your_doctors: "Вашите лекари",
            recent_health_issues: "Последни здравни състояния",
            see_all_label: "Виж всички",
            see_vitals_history_label: "Виж история на измерванията",
            lab_result: "Импортирай Лабораторен резултат",
            select_label: "Избери",
            user_id: "Потребител ID",
            credentials_message: "Моля, въведете потребителя и паролата, които сте получили от лабораторията. Ние не запазваме тези данни и те се ползват само за извличане на резултатите от сайта на лабораторията.",
            cibalab: "Потребител ID",
            bodimed: "ID No.",
            ramus: "ID номер",
            status: "Потребител ID",
            pisanec: "Потребител ID",
            acibadem_sofia: "Потребителски номер",
            acibadem_varna: "Потребителски номер",
            acibadem_sofia_cityclinic: "Потребителски номер",
            continue: "Продължи",
            username_required_label: "Моля, въведете потребителско име",
            select_date_label: "Изберете дата",
            create_lab_document: "Искате ли да създадете Документ с оригиналните резултати в секцията с Документи?",
            skip_label: "Пропусни",
            this_week: "Тази седмица",
            current_issues: "Текущи Здравни състояния",
            past_issues: "Минали Здравни състояния",
            all_issues: "Всички Здравни състояния",
            chronic_condition: "Хронично заболяване",
            allergy_condition: "Алергия",
            vitals: "Измервания",
            labs: "Лаб. резултати",
            add_health_issue_label: "Добави здравно състояние",
            edit_health_issue_label: "Редактирай здравно състояние",
            symptoms_history: "История на симптомите",
            more: "Повече",
            less: "По-малко",
            search_by_symptom: "Търсене на симптом",
            mark_lab_result_entry_deleted_modal_title: "Изтриване на лабораторен резултат",
            mark_lab_result_entry_deleted_modal_message: "Сигурни ли сте, че искате да изтриете този лабораторен резултат?",
            your_recent_symptoms: "Скорошни симптоми",
            choose_symptom: "Избери симптом",
            search_for_symptom: "Потърси симптом",
            no_symptoms_label: "Няма симптоми",
            search_for_clinicians_label: "Потърси лекари",
            hospitals_label: "Здравно заведение",
            search_for_specialties_or_clinicians_label: "Потърси лекари или специалисти...",
            back_to_clinicians_label: "Назад към лекари",
            examinations_label: "Прегледи и консултации",
            biography_label: "Биография",
            document_name_label: "Име на документ",
            size_label: "Размер",
            date_added_label: "Добавен на",
            document_label: "Добави Документи",
            update_document: "Редактирай Документ",
            book_label: "Запази час",
            create_appointment_successful_message: "Вие успешно запазихте час",
            mark_medication_entry_deleted_modal_message: "Сигурни ли сте, че искате да изтриете това лекарство?",
            med_start_date: "В сила от ",
            no_one_waiting_for_your_approval_label: "Няма потребители, който очакващи Вашето одобрение",
            not_sharing_data_with_anyone_label: "Не споделяте информация с никого",
            not_waiting_for_anyones_approval_label: "Няма потребители, които не са приели вашата молба за споделяне",
            title_label: "Заглавие",
            update_note: "Редактирай съобщение",
            create_note: "Създай съобщение",
            note_required_message: "Съобщението не може да бъде празна",
            delete_note_modal_header: "Потвърдете действието",
            delete_note_modal_text: "Сигурни ли сте, че искате да изтриете това съобщение?",
            general_note_label: "Общо съобщение",
            new_note_label: "Ново съобщение",
            back_to_appointments_label: "Назад към часове",
            note_label: "Съобщение",
            pay_label: "Плати",
            schedule: "График",
            duration: "Продължителност",
            frequency: "Колко често",
            frequency_times: "Брой на приеми",
            frequency_hours: "Брой часове",
            frequency_times_day: "Х пъти на ден",
            frequency_every_hours: " Всеки Х часа",
            free_label:"Безплатен",
            free_label_consultation:"Безплатна",
            take: "Вземи",
            rest: "Почивай",
            cycle_day: "Ден на цикъла",
            menstruation_stats:"Менструални статистики",
            cycle: "Цикъл",
            last_cycle:"Последен цикъл",
            cycle_average:"Средно",
            cycle_shortest:"Най-къс",
            cycle_longest:"Най-дълъг",
            period_average:"Средно",
            period_shortest:"Най-къса",
            period_longest:"Най-дълга",
            length: "Продължителност",
            period:"Менструация",
            last_period_length: "Последна менструация",
            duration_range_picker_label: "Период на справката",
            year:"година",
            years:"години",
            day:"ден",
            download_menstruation_report:"Изтегли справка за менструация",
            menstruation_report:"Справка за менструация",
            history:"История",
            change_time: "Промени време",
            weekend_schedule: "Използвай различен график през уикендите",
            no_documents_label:"Нямате създадени документи.",
            no_medications: "Няма добавени лекарства",
            add_medication: "Добавете лекарство",
            update_medication: "Обновете лекарство",
            no_symptoms_after_filters: "Няма намерени симптоми след филтриране",
            no_logs_after_filters: "Няма намерени записи след филтриране",
            add_notes: "Добави бележки",
            pay_now: "Плати сега",
            no_appointment_notes: "Няма съобщения за този преглед",
            agreement_selected_required: "Моля, съгласете се с нашите \"Условия за ползване\", за да продължите напред",
            look_for_hospitals_label: "Провери Здравни заведения",
            logbook_type_label: "Вид дневник",
            radio_active_label: "Активни",
            radio_inactive_label: "Неактивни",
            my_medications: "Моите лекарства",
            all_medications: "Всички лекарства",
            pickup_call_button_label: "Вдигни",
            hang_up_button_label: "Затвори",
            incoming_call_label: "Входящо повикване",
            inactive_plan: "Деактивиран план",
            taken_on_information: "Прието {amount} {unit} на {dateTime}",
            inventory: "Наличност",
            patient_uin: "ПИН",
            skip_reason_none: "Без причина",
            skip_reason_forgot_to_take: "Забравих да взема",
            skip_reason_med_not_near_me: "Лекарството не е при мен",
            skip_reason_asleep: "Бях заспал(а)",
            skip_reason_too_busy: "Бях твърде зает(а)",
            skip_reason_out_of_med: "Свърших лекарството",
            skip_reason_side_effects: "Заради странични ефекти",
            skip_reason_other: "Други причини",
            skip_reason_expensive: "Твърде скъпо е",
            skip_reason_dose_not_needed: "Нямах нужда от тази доза",
            planned_for_information: "По план за",
            skipped_information: "Пропуснати {amount} {unit}",
            delete_plan: "Изтриване на лекарство",
            make_inactive:"Деактивиране на план",
            make_active:"Активиране на план",
            select_end_date: "Изберете крайна дата",
            make_plan_inactive: "Сигурни ли сте, че искате да деактивирате този план?",
            delete_med_history: "Изтриване на историята на лекарството",
            mark_med_history_deleted_modal_message: "Желаете ли да бъдат изтрити всички минали записи за това лекарство от дневника?",
            no_label: "Не",
            pick_date_and_time: "Изберете дата и час",
            reschedule_label: "Смени часа",
            take_as_planned_label: "Прием по план, в {time}",
            take_now_label: "Прием сега, в {time}",
            take_at_selected_time_label: "Прием в избраното време",
            skipping_reason: "Моля, посочете причина за пропускане на дозата?",
            reason_notes: "Бележки за причините",
            rescheduled_for_label: "Сменен график за ",
            reset_medication_msg: "Сигурни ли сте, че искате да нулирате приема и да изтриете записа от дневника?",
            reset_medication_label: "Нулиране на прием",
            monday: "понеделник",
            tuesday: "вторник",
            wednesday: "сряда",
            thursday: "четвъртък",
            friday: "петък",
            saturday: "събота",
            sunday: "неделя",
            no_hours_available_primary_label: "Няма свободни часове",
            no_temporary_links_label_label: "Нямате връзки за временен достъп",
            selected_symptoms: "Избрани Cимптоми",
            Captcha: "Въведете изписания код",
            EGN: "ЕГН",
            version: "версия",
            accept_appointment_confirmation_header: "Сигурни ли сте. че искате да одобрите прегледа при {fullname} на {date}?",
            accept_appointment_confirmation_body: "Прегледът ще бъде одобрен веднага.",
            accept_appointment_label: "Одобри",
            payment_option_modal_body: "Изберете метод на плащане на прегледа при {fullname} на {date}",
            payment_option_consultation_modal_body: "Изберете метод на плащане на писмената консултация при {fullname} създадена на {date}",
            pay_with_card_button: "Плати с карта",
            pay_with_epay_button: "Плати с ePay",
            epay_payment_option_descr: "Използвайте тази опция, ако имате регистрация в ePay",
            cc_payment_option_descr: "Използвайте тази опция, за да платите с вашата дебитна или кредитна карта",
            payment_payed_waiting_body_text: "Моля, изчакайте докато получим потвърждение на Вашето плащане",
            payment_canceled_body_text: "Плащането е отказано!",
            payment_completed_body_text: "Плащането е завършено успешно!",
            total_sum: "сума",
            cancellation_policy: "Условията за отказ",
            cancellation_policy_agreement_label_first_part: "Сложете отметка тук, за да посочите, че сте прочели и сте съгласни с ",
            terms_and_conditions: "Условия за ползване",
            login_form_temp_locked_down_message: "Вашият акаунт е временно заключен заради няколко последователни неуспешни опити за влизане. Моля изчакайте няколко минути преди да се опитате да влезете отново.",
            login_form_must_reset_password_message: "Вашият акаунт е заключен заради последователни неуспешни опити за влизане. Можете да отключите Вашият акаунт като смените паролата си през функцията Забравена парола.",
            pay_before: "Плати преди",
            missed_payment:"Неплатен преди крайния срок",
            missed_payment_consultation:"Неплатена преди крайния срок",
            start_video:"Включи видео",
            stop_video:"Изключи видео",
            no_video:"Няма видео",
            no_audio:"Няма аудио",
            mute:"Изключи",
            unmute:"Включи",
            join_now: "Влез в стаята",
            enter_examination_room: "Влез в стаята за преглед",
            entering_examination_room: "Влизане в стаята за преглед",
            audio:"Аудио",
            audio_input:"Аудио устройства",
            video:"Видео",
            video_input:"Видео камери",
            audio_and_video_settings: "Аудио и видео настройки",
            no_local_audio: "Няма аудио устройства",
            done_btn:"Готово",
            no_local_video:"Няма видео камера",
            You:"Вие",
            current_video_call:"Текуща видео сесия",
            camera_permissions_denied_heading:"Няма достъп до видеокамера:",
            camera_permissions_denied_message:"Отказан достъп до видеокамерата. Моля разрешете на браузера да достъпи видеокамерата.",
            microphone_permissions_denied_heading:"Няма достъп до микрофон:",
            microphone_permissions_denied_message:'Отказан достъп до микрофон. Моля разрешете на браузера да достъпи микрофона.',
            not_allowed_error_heading:'Няма достъп до входни устройства:',
            not_allowed_error_message:'Операционната система е блокирала достъпа на браузера до микрофона или видеокамерата. Моля проверете настройките на операционната си система.',
            not_allowed_error_message_alt:'Отказан достъп до микрофон и камера. Моля разрешете на браузера да достъпи микрофона и видеокамерата.',
            not_found_error_heading:'Не са намерени микрофон или видеокамера:',
            not_found_error_message:'Браузерът Ви не може да достъпи микрофона или видеокамерата. Моля проверете дали всички устройства са включени.',
            connection_error_heading:'Комуникационна грешка:',
            connection_error_message:'Прекъсната връзка или прекратяване на медия. Моля влезте отново в стаята и изчакайте другите участници да се включат.',
            error_acquiring_media_heading:'Грешка при опит за достъп до видеокамера или микрофон:',
            no_camera_or_microphone_detected_heading:'Не са намерени видеокамера или микрофон:',
            no_camera_or_microphone_detected_message:'Другите участници няма да могат да ви чуват и виждат.',
            no_camera_detected_heading:'Не е намерена камера:',
            no_camera_detected_message:'Другите участници няма да могат да ви виждат.',
            no_microphone_detected_heading:'Не е намерен микрофон:',
            no_microphone_detected_message:'Другите участници няма да могат да ви чуват.',
            error_occurred:"Възникна грешка! Моля свържете се с отдела за поддръжка на support@medrec-m.com.",
            confirm_delete:"Сигурни ли сте, че искате да изтриете акаунта си в Medrec-M?",
            confirm_delete_advisory:"Изтриването на акаунта ви ще изтрие всичките ви данни и Вашите свързани профили които нямат други свързани към тях профили на други потребители.",
            delete_word_confirm_label:"Моля напишете думата DELETE в полето по долу",
            share_all_data_explanation: "Важно! Споделената информация ще е достъпна от лекаря докато прегледът не бъде отменен или 14 дни след запазения час за преглед!",
            share_all_data_label: "Споделете цялата здравна информация с лекаря",
            controlled_by:"Профили с достъп",
            attach_documents_to_appointment_title:"Прикачи документи",
            attach_documents_to_appointment_explanation:"Прикачените документи ще бъдат достъпни от лекаря докато не ги разкачите!",
            results_documents:"Прикачени документи от мен",
            results_documents_button:"Прикачи документи",
            get_for_ios:"Свали за iOS",
            appointment_complete:"Този преглед е завършен.",
            get_for_android:"Свали за Android",
            choose_mobile_app_text:"Свалете нашето приложение за мобилни устройства",
            liters_per_minute:"л/мин",
            peak_flow:"ВЕД",
            entry_type_peak_flow:"ВЕД",
            peak_flow_label:"ВЕД",
            peak_flow_required_message:"ВЕД е задължително поле",
            peak_flow_wrong_format_message:"ВЕД е в грешен формат",
            peak_flow_out_of_range_message:"ВЕД е извън допустимите стойности",
            peak_flow_with_symptoms:"със симптоми",
            peak_flow_symptoms_label: "Ако през деня сте изпитали някои от симптомите по-долу, моля, отбележете ги!",
            peak_flow_inhaler_label: "Използван е инхалатор",
            peak_flow_asthma_symptoms_label: "Наблюдавани са симптоми, като задух, стягане в гърдите, кашляне или изтръпване",
            peak_flow_night_asthma_symptoms_label: "Събуждане през нощта от астматични симптоми",
            peak_flow_daily_activity_label: "Затруднение при извършването на нормалните ежедневни дейности",
            oxygen_saturation_label:"Кислородна сатурация",
            fill_in_the_questionnaire:"За този преглед лекарят изисква да попълните въпросник.",
            to_the_questionnaire:"Попълни въпросник",
            edit_questionnaire:"Редактирай въпросник",
            reset_questionnaire_answers:"Изтрийте отговорите",
            next_question_btn:"Следващ въпрос",
            new_message_from: "От",
            previous_question_btn:"Предишен въпрос",
            export_medical_profile:"Свали медицинската история",
            marketing_agreements_message:"Искам да получавам последните медицински и здравни новини по имейл.",
            until_label: "до",
            additional_results:"Допълнителни тестове и процедури",
            peak_flow_norm:"ВЕД норма (л/мин)",
            additional_info:"Допълнителна информация",
            book_the_appointment_btn:'Запази часа',
            confirm_appointment_btn:"Потвърди преглед",
            no_additional_exam_results:"Няма въведен резултат за този преглед.",
            no_additional_exam_results_consultation:"Няма въведен резултат за тази писмена консултация.",
            next_appointment:"Предстоящ преглед",
            active_text_consultations:"Активни писмени консултации",
            last_message: "%1 написа",
            text_consultation: "Писмена консултация",
            select_user_for_appointment:"Избор на потребител",
            text_only_payment_explanation:"Внимание! За да започнете тази писмена консултация, трябва да заплатите напълно обявената цена! Можете да заплатите по-късно, но лекарят ще види съобщението след като плащането бъде обработено успешно.",
            text_only_pre_payment_text: "Успешно създадохте писмена консултация.\n\nВнимание! Лекарят ще види съобщението след като заплатите напълно обявената цена.\n\nКрайният срок за плащане е %1. При просрочване, тази консултация ще бъде отменена автоматично.",
            text_only_pay_later_warning: "Внимание! Лекарят ще види съобщението и времето за отговор ще започне да тече след като писмената консултация бъде заплатена.",
            pay_later_btn: "Плати по-късно",
            search_with: "Потърсете с ",
            disclaimer: "Допълнителни условия",
            show_info_modal: "Допълнителна информация",
            search_medicine_with: "Потърсете лекарство с ",
            results_provider_documents:"Прикачени документи от лекаря",
            override_location_to_online:"Проведи прегледа онлайн",
            override_location_to_online_explanation:"Ако изберете опцията отдолу, лекарят ще направи видео консултация в избрания час вместо преглед на място.",
            MORNING:"сутрин",
            EARLY_MORNING:"рано сутрин",
            LATE_MORNING:"късно сутрин",
            NOON:"по обед",
            AFTERNOON:"следобед",
            EARLY_AFTERNOON:"ранен следобед",
            LATE_AFTERNOON:"късен следобед",
            EVENING:"вечер",
            EARLY_EVENING:"рано вечер",
            LATE_EVENING:"късно вечер",
            NIGHT:"през нощта",
            AFTER_SLEEP:"след сън",
            BEFORE_SLEEP:"преди сън",
            UPON_WAKING:"при събуждане",
            WITH_MEAL:"с храна",
            WITH_BREAKFAST:"със закуска",
            WITH_LUNCH:"с обяд",
            WITH_DINNER:"с вечеря",
            BEFORE_MEAL:"преди храна",
            BEFORE_BREAKFAST:"преди закуска",
            BEFORE_LUNCH:"преди обяд",
            BEFORE_DINNER:"преди вечеря",
            AFTER_MEAL:"след храна",
            AFTER_BREAKFAST:"след закуска",
            AFTER_LUNCH:"след обяд",
            AFTER_DINNER:"след вечеря",
            intake_instructions_note_label: "Инструкции за прием",
            already_existing_appointment_body: "Вече имате запазен час за преглед при този лекар на %1.",
            pay_online_or_onsite:"Прегледът може да бъде заплатен при посещението или онлайн",
            main_user: "Основен профил",
            select_user_for_appointment_modal_body:"Изберете потребител, за когото искате да запазите час за преглед или текстова консултация.",
            patient_label: "Пациент",
            adult_btn_label: "Възрастен",
            child_btn_label: "Дете",
            child_profile_section: "Детски профил",
            account_section: "Регистрационни данни на родител/настойник",
            child_fullname_label:"Име на детето",
            register_form_child_full_name_required:"Име на детето е задължително поле",
            adult_child_registration_explanation_text:"Регистрацията на <strong>Дете (лице под 18 години)</strong> " +
                "винаги е съпроводено с попълване на данни и на родител/настойник, " +
                "като се създава основен родителски профил, с който се управлява профила на детето.<br/><br/>"+
                "След регистрацията на  <strong>Възрастен (лице навършило 18 години)</strong> можете да добавите свързан семеен профил " +
                "на ваш роднина, близък или дете.",
            adult_child_registration_explanation_ps:"След успешна регистрация ще бъдете пренасочени към платформата Medrec:M, където да завършите запазването на часа за преглед.",
            name_field_required: "Име е задължително",
            nhis_prescription_label: "Рецепта",
            nhis_supplement: "Нелекарствени продукти",
            medical_notices: "Медицински бележки",
            medical_notice_nrn_number:"НРН номер",
            medical_notice_issued_on_label:"Издадена на",
            medical_notice_reason_label:"Основание",
            medical_notice_location_view_label:"Място на лечението",
            medical_notice_from:"От",
            medical_notice_for_period:"За период",
            medical_notice_institution_label:"Да послужи пред",
            medical_notice_note_label:"Уточнения към бележката",
            medical_notice_diagnosis: "Заболяване",
            remove_all_documents_from_appointment_btn:"Разкачи всички документи",
            nhis: {
                ereferral: {
                    ereferrals:"Направления",
                    nrn_number: "НРН",
                    category:"Категория на направлението",
                    authoredOn:"Дата на издаване на направлението",
                    activity:"Назначени медико-диагностични дейности",
                    qualificationTarget: "Лекарска специалност, за която се насочва пациента",
                    qualificationTargets: "Лекарски специалности, за която се насочва пациента",
                    admissionType:"Тип на приема",
                    directedBy:"Насочен от",
                    specializedActivityCode: "Специализирани дейности или манипулации, или процедури за физикална и рехабилитационна медицина",
                    examType:"Вид на прегледа по НЗОК, за който се издава направлението за медицинска експертиза",
                    workIncapacityReason:"Причина за издаване на направлението",
                    clinicalPathway:"Клинична пътека по НЗОК",
                    outpatientProcedure:"Амбулаторни процедури по НЗОК, за които се изпраща пациента",
                }
            },
            medication: {
                unit: {
                    label: {
                        ampule: {
                            display_long: 'ампула',
                            display_long_plural: 'ампули',
                            display_short: '',
                            display_short_plural: ''
                        },
                        application: {
                            display_long: 'прилагане',
                            display_long_plural: 'прилагания',
                            display_short: '',
                            display_short_plural: ''
                        },
                        capsule: {
                            display_long: 'капсула',
                            display_long_plural: 'капсули',
                            display_short: '',
                            display_short_plural: ''
                        },
                        drop: {
                            display_long: 'капка',
                            display_long_plural: 'капки',
                            display_short: '',
                            display_short_plural: ''
                        },
                        gram: {
                            display_long: 'грама',
                            display_long_plural: 'грама',
                            display_short: 'г',
                            display_short_plural: 'г'
                        },
                        injection: {
                            display_long: 'инжекция',
                            display_long_plural: 'инжекциии',
                            display_short: '',
                            display_short_plural: ''
                        },
                        mg: {
                            display_long: 'милиграм',
                            display_long_plural: 'милиграма',
                            display_short: 'мг',
                            display_short_plural: 'мг'
                        },
                        ml: {
                            display_long: 'милилитър',
                            display_long_plural: 'милилитри',
                            display_short: 'мл',
                            display_short_plural: 'мл'
                        },
                        packet: {
                            display_long: 'саше',
                            display_long_plural: 'сашета',
                            display_short: '',
                            display_short_plural: ''
                        },
                        patch: {
                            display_long: 'пластир',
                            display_long_plural: 'пластири',
                            display_short: '',
                            display_short_plural: ''
                        },
                        piece: {
                            display_long: 'част',
                            display_long_plural: 'части',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tablet: {
                            display_long: 'таблетка',
                            display_long_plural: 'таблетки',
                            display_short: '',
                            display_short_plural: ''
                        },
                        puff: {
                            display_long: 'инхалиране',
                            display_long_plural: 'инхалирания',
                            display_short: '',
                            display_short_plural: ''
                        },
                        spray: {
                            display_long: 'впръскване',
                            display_long_plural: 'впръсквания',
                            display_short: '',
                            display_short_plural: ''
                        },
                        suppository: {
                            display_long: 'супозитория',
                            display_long_plural: 'супозитории',
                            display_short: ''
                        },
                        tbsp: {
                            display_long: 'супена лъжица',
                            display_long_plural: 'супена лъжици',
                            display_short: 'с.л.',
                            display_short_plural: 'с.л.'
                        },
                        tsp: {
                            display_long: 'чаена лъжица',
                            display_long_plural: 'чаена лъжици',
                            display_short: 'ч.л.',
                            display_short_plural: 'ч.л.'
                        },
                        unit: {
                            display_long: 'единица',
                            display_long_plural: 'единици',
                            display_short: '',
                            display_short_plural: ''
                        }
                    }
                }
            },
            logentry_how_to_do_it: "Как се ползва",
            logentry_instructions_title: "Как да измеря",
            logentry_blood_pressure_instructions1: "Избягвайте алкохол и кофеинови напитки минимум 30 минути преди измерването",
            logentry_blood_pressure_instructions2: "Седнете спокойно за 5 минути, с облегнат гръб и без да кръстовате крака.",
            logentry_blood_pressure_instructions3: "Лакътя да се намира на нивото на сърцето.",
            logentry_blood_pressure_instructions4: "Измерването да е на гола ръка.",
            logentry_blood_pressure_instructions5: "Не говорете по време на измерването.",
            logentry_blood_pressure_instructions6: "Повторете измерването след минута. При голямо разминаване, измерете трети път и въвете средна стойност от всички измервания",
            logentry_respiratory_rate_instructions0: "Може да помолите друго лице да измери Вашата дихателна честота.",
            logentry_respiratory_rate_instructions1: "Седнете в изправено положение.",
            logentry_respiratory_rate_instructions2: "Нека другият човек се опита да брои Вашите вдишвания без Ваше знание. Ако знаете, може да се опитате да контролирате вдишванията си. Това може да доведе до неверен резултат.",
            logentry_respiratory_rate_instructions3: "Нека другият човек използва часовник в едната си ръка и да брои Вашите вдишвания в продължение на 60 секунди. Може да използва някой от следните методи да брои:\n\n  - Да следи как се издига и снижава гръдният кош. Едно издигане и едно снижаване се брои за 1 вдишване.\n\n  - Да слуша Вашите вдишвания.\n\n  - Да си сложи другата ръка на гръдния Ви кош и да усеща издиганията и снижаванията.",
            logentry_oxygen_saturation_instructions1: "Оксиметрите (или пулсоксиметрите) за домашна употреба могат да се закупят онлайн или от по-големите аптеки. Те се използват за измерване на нивото на кислород в кръвта (кислородната сатурация).",
            logentry_oxygen_saturation_instructions2: "Говорете с вашия личен лекар преди да започнете да измервате вкъщи.",
            logentry_oxygen_saturation_instructions3: "Дисплеят на оксиметъра (пулсоксиметъра) показва процента на кислород в кръвта Ви. За здрав човек нормалното ниво на кислородна сатурация е около 95–100%.",
            logentry_oxygen_saturation_instructions0: "Кислородът се пренася във вашите червени кръвни телца (еритроцити) чрез молекула, наречена хемоглобин. Оксиметрите (пулсоксиметрите) измерват колко кислород пренася хемоглобинът в кръвта Ви. Тази величина се нарича кислородна сатурация и е процент (до максимум 100).",
            logentry_temperature_instructions_title1: "Използване на цифров орален термометър",
            logentry_temperature_instructions11: "Измийте ръцете си със сапун и топла вода.",
            logentry_temperature_instructions12: "Използвайте чист термометър, който е измит в студена вода, дезинфекциран със спирт и след това изплакнат, за да отстраните спирта.",
            logentry_temperature_instructions13: "Не яжте и не пийте нищо най-малко пет минути, преди да премерите температурата си, защото температурата на храната или напитката може да направи отчитането неточно. Трябва да държите устата си затворена през това време.",
            logentry_temperature_instructions14: "Поставете върха на термометъра под езика.",
            logentry_temperature_instructions15: "Задръжте термометъра на същото място за около 40 секунди.",
            logentry_temperature_instructions16: "Показанията ще продължат да се увеличават и по време на измерването символът F (или C) ще мига.",
            logentry_temperature_instructions17: "Обикновено термометърът ще издаде звуков сигнал, когато направи последното отчитане (обикновено след около 30 секунди). Запишете температурата си в Medrec:M.",
            logentry_temperature_instructions18: "Изплакнете термометъра в студена вода, почистете го със спирт и изплакнете отново.",
            logentry_temperature_instructions_title2: "Използване на цифров подмишничен термометър",
            logentry_temperature_instructions21: "Свалете ризата и поставете върха на термометъра в подмишницата. Уверете се, че подмишницата ви е суха, за да получите най-точното отчитане.",
            logentry_temperature_instructions22: "Дръжте термометъра на място, като сгънете ръката си през гърдите.",
            logentry_temperature_instructions23: "Обикновено термометърът ще подаде звуков сигнал, когато се извърши отчитането (този метод може да отнеме повече от 30 секунди).",
            logentry_temperature_instructions24: "Махнете термометъра и запишете температурата в Medrec:M.",
            logentry_temperature_instructions25: "Почиствайте термометъра със сапун и вода или със спирт, като винаги изплаквате като последна стъпка.",
            logentry_temperature_instructions_title3: "Използване на ушен термометър",
            logentry_temperature_instructions31: "Издърпайте внимателно назад горната част на ухото, за да отворите ушния канал.",
            logentry_temperature_instructions32: "Поставете защитния капак на върха на термометъра.",
            logentry_temperature_instructions33: "Внимателно поставете термометъра, докато ушният канал не бъде напълно запечатан.",
            logentry_temperature_instructions34: "Натиснете и задръжте бутона за 1–2 секунди, докато чуете звуков сигнал (следвайте инструкциите на производителя).",
            logentry_temperature_instructions35: "Извадете термометъра, изхвърлете капака и запишете температурата в Medrec:M.",
            logentry_temperature_instructions_note26: "Ректалните температури се считат за най-точната индикация за температурата на тялото. Оралните и подмишничните показания на температурата са около 0.3°C до 0.6°C (½°F до 1°F) под ректалните. Добавете тези числа към показанията за устна и аксиларна температура за най-точно отчитане.",
            logentry_hydration_instructions_title: "Предизвикателство “Хидратиране”",
            logentry_hydration_instructions_title1: "Начало – Седмица 1",
            logentry_hydration_instructions11: "Запишете колко вода пиете през времето, прекарано на работа. Имайте впредвид, че средно 1 чаша е около 150 мл. Отбележете как сте се чувствали през деня.",
            logentry_hydration_instructions12: "В края на седмицата изчислете средната дневна консумация на вода. Трябва да се стремите да пиете 4–6 чаши (600мл-900мл) на ден за времето, което прекарвате на работа.",
            logentry_hydration_instructions_title2: "Седмици 2–4",
            logentry_hydration_instructions21: "В следващите седмици се стремете постепенно да увеличавате приема на вода, като се стремите към целта от 4–6 чаши (600мл-900мл) на ден за времето, което прекарвате на работа.",
            logentry_hydration_instructions22: "Продължете да записвате колко вода пиете на работа всеки ден. В края на всеки ден записвайте как сте се чувствали. Например имало ли е промени в нивото на концентрация, чувство на умора или раздразнителност?",
            logentry_hydration_instructions23: "Помислете за причините, поради които не сте пили повече вода. Обсъдете с колегите си начини за справяне с тези проблеми.",
            logentry_hydration_instructions_title3: "Факторите, които ви пречат да пиете повече течности по време на работа, могат да бъдат:",
            logentry_hydration_instructions31: "Лошо местоположение на водните съоръжения, което ги прави недостъпни.",
            logentry_hydration_instructions32: "Ограничени възможности за прекъсване за да пиете вода.",
            logentry_hydration_instructions33: "Липса на мотивация да прекъснете работа, за да пиете течности.",
            logentry_hydration_instructions34: "Нежелание да се пие вода, поради неудобство, породено от използването на общи тоалетни или лоша хигиена в тоалетните.",
            logentry_weight_instructions0: "Сдобийте се с добър кантар.",
            logentry_weight_instructions1: "Мерете се поне два пъти на ден: когато се събудите, преди да си легнете и по средата на деня (ако е възможно).",
            logentry_weight_instructions2: "При възможност, премерете се на голо. Дрехите са допълнително тегло и това тегло варира, затова избягвайте го, ако можете.",
            logentry_weight_instructions3: "Вероятно няма да имате възможност да се премерите на голо по средата на деня, но за сметка на това може да се премерите преди и след като се облечете, за да определите какво е теглото на дрегите (приблизително). След това може да извадите това тегло когато се мерите облечени.",
            logentry_height_instructions0: "Първо, намерете равна част на пода без меки повърхности като килими, мокети и други подобни, и права равна част от стена.",
            logentry_height_instructions1: "Свалете обувките си. Махнете плитки, ластици за коса, диадеми и всичко друго от главата си, което може да попречи на точно измерване.",
            logentry_height_instructions2: "Свалете обемно облекло, което може да ви попречи да стоите плътно изправени до стената.",
            logentry_height_instructions3: "Застанете с изравнени крака плътно до стената и поставете петите си там където се срещат стената и пода. Главата, раменете и задните ви части трябва да докосват стената.",
            logentry_height_instructions4: "Изправете се и гледайте право напред. Линията на зрението и брадичката ви трябва да са паралелни на пода.",
            logentry_height_instructions5: "Помолете някой да постави равен предмет (като линия или книга с твърди корици) на стената под правилен ъгъл. Помолете ги да свалят предмета докато не застане леко върху главата ви, докато го поддържат под правилен ъгъл към стената.",
            logentry_height_instructions6: "Леко маркирайте стената с молив, там където предметът се среща с главата ви.",
            logentry_height_instructions7: "Използвайте рулетка - най-добре метална, която ще остане изправена - за да измерите разстоянието от пода до маркера на стената.",
            logentry_height_instructions8: "Отблежете измерването с точност до 0.1 сантиметър или 1/8 инч.",
            logentry_glucose_instructions0: "Измийте ръцете си добре.",
            logentry_glucose_instructions1: "Вкарайте лента за тестване в глюкомера си.",
            logentry_glucose_instructions2: "Убодете отстрани върха на пръста си с ланцетата на убождащото си устройство.",
            logentry_glucose_instructions3: "Внимателно стиснете и масажирайте пръста, докато се оформи капка кръв.",
            logentry_glucose_instructions4: "Докоснете и задръжте ръба на лентата за тестване към капката кръв.",
            logentry_glucose_instructions5: "Глюкомерът ще покаже нивото на кръвната ви захар на екрана след няколко секунди.",
            logentry_ketones_instructions0: "Измийте ръцете си добре.",
            logentry_ketones_instructions1: "Заредете убождащото устройство с иглата, следвайки упътването.",
            logentry_ketones_instructions2: "Поставете лента за тестване на кетони в устройството.",
            logentry_ketones_instructions3: "Убодете пръста си, за да изкарате малка капка кръв, използвайки убождащото устройство.",
            logentry_ketones_instructions4: "Докоснете капката кръв с тест лентата и проверете резултатите.",
            logentry_ketones_instructions5: "Изхвърлете тест лентата и иглата според упътванията.",
            logentry_urine_ph_instructions0: "Сдобийте се с pH тест лента. Тази лента измерва киселинно-алкалното състояние на всяка течност. Измервания в долния край на скалата показват нивото на киселинност, а тези в горната част на скалата - алкално състояние. Пълни инструкции как да ги ползвате идват със самите ленти, но има няколко лесни стъпки да проверите баланса на pH в тялото си вкъщи.",
            logentry_urine_ph_instructions1: "Измерете сутринта. Веднага след ставане, при възможност след 6 часа сън без да ставате за да уринирате. Вземете тестова лента. Уринирайте директно върху лентата или съберете урина в чаша и потопете лентата в нея.",
            logentry_urine_ph_instructions2: "Моля имайте предвид, че първата урина за деня е най-ценна при проверка на pH. Ако не можете да изкарате 6 часа без да уринирате през нощта, просто след ставане измерете с първата урина за деня.",
            logentry_urine_ph_instructions3: "Проверете цвета на резултата. Когато тестовата лента се намокри, тя ще придобие цвят. Цветът е свързан с нивото на киселинност или алкидност на урината ви, като диапазонът е от жълто до тъмно синьо. Сверете цвета на вашата тестова лента с диаграмата на гърба на теста.\n - Число под 6.5 означава, че урината ви е с по-висока киселинност. Колкото по-малко е числото, толкова по-висока киселинност.\n - Идеалния резултат при тестване на урина трябва да е между 6.5 и 7.5.",
            logentry_menstruation_instructions0: "Нормално количество на менструална течност за един цикъл е между 5 мл и 80 мл.",
            logentry_menstruation_instructions1: "Някои медоди за защита от забременяване може да окажат ефект върху това колко тежък е цикълът ви.",
            logentry_menstruation_instructions2: "Загуба на повече от 80 мЛ менструална течност се смята за тежко менструално кървене.",
            logentry_menstruation_instructions_title1: "Пресмятане на обема на цикъла",
            logentry_menstruation_instructions3: "Ако имате менструална чаша, те обикновено имат мерни единици за обем (напр. 10 мл, 15 мл, 25 мл) отбелязани отстрани на чашата, за да ви помогнат да прецените колко течност сте загубили.",
            logentry_menstruation_instructions4: "По принцип, количеството загубена течност може да се определи при използване на превръзки или тампони, в зависимост от размера и попивателните качества на продукта. Напълно пропит лек тампон може да задържа до 3 ml течност, докато напълно пропит супер тампон може да задържи до 12 мл (3,8).",
            logentry_menstruation_instructions5: "Напълно пропита обикновена дневна превръзка може да задържи до около 5 мл течност, а напълно пропита нощтна превръзка може да задържи до 10–15 мл.",
            logentry_menstruation_instructions6: "Ако постоянно сменяте напълно пропити тампон или превръзка на всеки 2 часа, това се смята за тежко менструално кървене и трябва да се консултирате с медицински специалист.",
            logentry_peak_flow_instructions0: 'Преди всяка употреба се уверете, че плъзгащият се маркер или стрелката на ВЕД-метъра е в долната част на номерираната скала (нула или най-ниското число на скалата).',
            logentry_peak_flow_instructions1: 'СЕДНЕТЕ. Отстранете дъвка или всякаква храна от устата си. Поемете дълбоко въздух (колкото можете дълбоко). Поставете мундщука на ВЕД-метъра в устата си. Затворете плътно устните си около мундщука. Не забравяйте да държите езика си далеч от мундщука. На един дъх издухайте възможно най-силно и възможно най-бързо (все едно духате свещи на торта за рожден ден). Издухвайте „бърз силен взрив“, вместо „бавно издухване“, докато не изпразните почти целия въздух от белите си дробове.',
            logentry_peak_flow_instructions2: 'Силата на въздуха, излизащ от белите Ви дробове, кара маркера да се движи по номерираната скала. Запишете измерената стойност на лист хартия.',
            logentry_peak_flow_instructions3: 'Повторете цялата процедура три пъти. (Знаете, че сте изпълнили процедурата правилно, когато числата и от трите опита са много близки.)',
            logentry_peak_flow_instructions4: 'ВАЖНО: Въведете НАЙ-ВИСОКАТА от трите оценки в дневника. Не изчислявайте средна стойност.',
            logentry_peak_flow_instructions5: 'Измервайте своя пиков дебит приблизително по едно и също време всеки ден. Вие и вашият лекар можете да определите най-доброто време. Едно предложение е да измервате своя пиков дебит два пъти дневно между 7 и 9 часа сутринта и между 18 и 20 часа. Може да искате да измерите своя пиков дебит преди или след употреба на Вашето лекарство. Някои хора измерват пиковия поток както преди, така и след приема на лекарства. Опитайте се да го правите по един и същи начин всеки път.',
            about: 'За нас',
            contact: 'Контакти'
        }
    }
